
import { parseFieldValue } from "./helpers";

import BooleanInput from "./Inputs/BooleanInput.vue";
import DropdownInput from "./Inputs/DropdownInput.vue";
import CurrencyDropDown from "../CurrencyDropDown/index.vue";
import DateInput from "./Inputs/DateInput.vue";
import MultilineInput from "./Inputs/MultilineInput.vue";
import TextInput from "./Inputs/TextInput.vue";
import NumberInput from "./Inputs/NumberInput.vue";
import EditKeyValuePair from "./EditKeyValuePair.vue";

export default {
  components: { BooleanInput, DropdownInput, DateInput, EditKeyValuePair, MultilineInput, NumberInput, TextInput, CurrencyDropDown },

  inheritAttrs: false,

  props: {
    header: {
      type: String,
      default: undefined,
    },

    /*
      [
        {
          id:Number,
          key:String,
          type:String("text"/"number"/"date"),
          value:Number/String,
          label:String,
          labelInput:String(optional)
        }
      ]
    */
    data: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    validateInput: {
      type: Boolean,
      default: false,
    },

    parseValues: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dataInternal: JSON.parse(JSON.stringify(this.data)),
      validate: {
        number: this.numberValidator,
      },
      dataValid: true,
    };
  },

  methods: {
    save() {
      this.$emit("save", this.findDifferences());
    },

    cancel() {
      this.$emit("close");
    },

    numberValidator(value) {
      return !isNaN(value);
    },

    findDifferences() {
      const differences = [];
      for (let i = 0; i < this.data.length; i++) {
        const original = this.data[i];
        const current = this.dataInternal[i];
        if (original.value !== current.value) {
          const field = this.parseValues ? { ...current, value: parseFieldValue(current) } : current;
          differences.push(field);
        }
      }
      return differences;
    },
  },
};
