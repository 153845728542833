// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_tYBF4{flex-direction:column}.container_tYBF4,.items_0TYhi{display:flex}.items_0TYhi.itemsGrid_MghgF{display:grid;grid-template-columns:1fr 1fr;grid-column-gap:1rem;-moz-column-gap:1rem;column-gap:1rem;grid-row-gap:1rem;row-gap:1rem}.item_8KYG\\+{display:flex;flex-wrap:nowrap}.item_8KYG\\+:not(:last-child){margin-bottom:1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_tYBF4",
	"items": "items_0TYhi",
	"itemsGrid": "itemsGrid_MghgF",
	"item": "item_8KYG+"
};
module.exports = ___CSS_LOADER_EXPORT___;
